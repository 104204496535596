/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
// assets
import SucessImg from '../../../images/shared-images/success.svg';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function InvitationSentBtn({ children }) {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */

  // State

  // Other hooks like reux or localization ...

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  /**
   * Computes the perimeter
   */

  /* ***************************** RENDER HELPERS ***************************** */

  /* ******************************** RENDERING ******************************* */

  return (
    <button type="button" className="invitation-sent-btn">
      <img src={SucessImg} alt="successBtn" />
      {children}
    </button>
  );
}

InvitationSentBtn.propTypes = {
  children: PropTypes.string.isRequired,
};

InvitationSentBtn.defaultProps = {};

export default InvitationSentBtn;
