/* eslint-disable implicit-arrow-linebreak */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// UI lib comoponents
import { Container } from 'react-grid-system';

// Local UI components
import InvitationLoadBtn from '../../../shared/UIKit/InvitationLoadBtn';
import InvitationSentBtn from '../../../shared/UIKit/InvitationSentBtn';
// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
import {
  baseUploads,
  baseUrl,
  verifyRequestEndPoint,
} from '../../../shared/APIs';
import { getToken } from '../../../shared/Services/auth';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function FirstSectionDetails({
  profileId,
  profileName,
  centerLocation,
  skills,
  imageUrl,
}) {
  /* ******************************** CONSTANTS ******************************* */
  const [invitationRequest, setInvitationRequest] = useState(false);
  const [invitationOnLoad, setInvitationOnload] = useState(false);
  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */
  async function verifyRequest() {
    if (profileId === undefined) return;
    try {
      const {
        data: { isFound },
      } = await axios.get(verifyRequestEndPoint(profileId), {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      setInvitationRequest(isFound);
    } catch (error) {
      console.log(error);
    }
  }

  async function createRequest() {
    setInvitationOnload(true);
    await axios
      .post(
        `${baseUrl}/users-permissions/user/me/requests`,
        { profileId },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        },
      )
      .then(() => {
        setInvitationRequest(true);
        setInvitationOnload(false);
      });
  }

  // State

  // Other hooks like reux or localization ...

  // Effects
  useEffect(() => {
    verifyRequest();
  }, []);

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  /**
   * Computes the perimeter
   */

  function getRightBtn() {
    if (invitationRequest === false && invitationOnLoad === false) {
      return (
        <button
          className="first-section-details-profile-button"
          type="button"
          onClick={() => createRequest()}
        >
          Envoyer une demande
        </button>
      );
    }
    if (invitationRequest === false && invitationOnLoad === true) {
      return <InvitationLoadBtn>invitation en cours</InvitationLoadBtn>;
    }
    if (invitationRequest === true && invitationOnLoad === false) {
      return <InvitationSentBtn>Demande envoyée</InvitationSentBtn>;
    }
    return null;
  }
  /* ***************************** RENDER HELPERS ***************************** */

  function RenderProfile() {
    return (
      <div className="first-section-details-profile">
        <img src={`${baseUploads}${imageUrl}`} alt="profile" />
        <h1>{profileName}</h1>
        <p className="location">{` Centre ${centerLocation?.data?.attributes?.name}`}</p>
        {getRightBtn()}
      </div>
    );
  }
  function RenderSkills() {
    return (
      <div className="first-section-details-skills">
        <h2>Compétences</h2>
        <div className="skills">
          {skills?.map(({ id, skill }) => (
            <span className="skill" key={id}>
              {skill}
            </span>
          ))}
        </div>
      </div>
    );
  }

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="first-section-details" fluid>
      <RenderProfile />
      <RenderSkills />
    </Container>
  );
}

FirstSectionDetails.propTypes = {
  profileId: PropTypes.number.isRequired,
  profileName: PropTypes.string.isRequired,
  centerLocation: PropTypes.string.isRequired,
  skills: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  imageUrl: PropTypes.string.isRequired,
};

FirstSectionDetails.defaultProps = {};

export default FirstSectionDetails;
/* <button
            className="first-section-details-profile-button"
            type="button"
            onClick={() => createRequest()}
          >
            Envoyer une demande
          </button> */
