/* eslint-disable function-paren-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';
// UI lib comoponents
import { Container } from 'react-grid-system';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function SecondSectionDetails({ profileDesc, experiences, speciality }) {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */

  // State

  // Other hooks like reux or localization ...

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  const formatDate = (date) => {
    if (
      typeof date === 'string' &&
      date.length > 0 &&
      date.split('/')?.length === 3
    ) {
      const formattedDate = date.split('/').reverse().join('-');
      return new Date(formattedDate);
    }

    return date;
  };

  /* ***************************** RENDER HELPERS ***************************** */
  /**
   * Renders the footer
   * @returns {React.Element} The rendered footer
   */
  function RenderProfileDescription() {
    return (
      <>
        <h2>{speciality}</h2>
        <p className="description-p">{profileDesc}</p>
      </>
    );
  }

  function renderProfileDetails({
    TitleExperience,
    description,
    finishDate,
    id,
    startDate,
  }) {
    let formattedStartDate = formatDate(startDate);
    let formattedFinisihDate = formatDate(finishDate);

    if (formattedStartDate instanceof Date) {
      formattedStartDate = `${
        formattedStartDate.getMonth() + 1
      }-${formattedStartDate.getFullYear()}`;
    }

    if (formattedFinisihDate instanceof Date) {
      formattedFinisihDate = `${
        formattedFinisihDate.getMonth() + 1
      }-${formattedFinisihDate.getFullYear()}`;
    }

    let date;

    if (startDate.length > 0 && finishDate.length > 0) {
      date = (
        <span>{`du ${formattedStartDate} au ${formattedFinisihDate} `}</span>
      );
    } else if (startDate.length > 0) {
      date = <span>{`du ${formattedStartDate}`}</span>;
    } else if (finishDate.length > 0) {
      date = <span>{`au ${formattedFinisihDate}`}</span>;
    }

    return (
      <div className="experience" key={id}>
        <h4>{TitleExperience}</h4>
        {date && date}
        <p>{description}</p>
      </div>
    );
  }

  const profileExperiences = experiences?.experience.map((exp) =>
    renderProfileDetails(exp),
  );
  const profileParcoursElife = experiences?.ParcoursElife.map((exp) =>
    renderProfileDetails(exp),
  );
  const profileDiplome = experiences?.Diplome.map((exp) =>
    renderProfileDetails(exp),
  );

  function ExperienceSection() {
    return (
      <div>
        {experiences?.ParcoursElife.length !== 0 ? (
          <div className="second-section-details-experienceSection">
            <h3>Parcours elife</h3>
            <div className="second-section-details-experienceSection-experiences">
              {profileParcoursElife}
            </div>
          </div>
        ) : (
          ''
        )}
        {experiences?.Diplome.length !== 0 ? (
          <div className="second-section-details-experienceSection">
            <h3>Diplômes et Formations</h3>
            <div className="second-section-details-experienceSection-experiences">
              {profileDiplome}
            </div>
          </div>
        ) : (
          ''
        )}
        {experiences?.experience.length !== 0 ? (
          <div className="second-section-details-experienceSection">
            <h3>Expériences professionnelles</h3>
            <div className="second-section-details-experienceSection-experiences">
              {profileExperiences}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
  //
  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="second-section-details" fluid>
      <RenderProfileDescription />
      <ExperienceSection />
    </Container>
  );
}

SecondSectionDetails.propTypes = {
  profileDesc: PropTypes.string.isRequired,
  experiences: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  speciality: PropTypes.string.isRequired,
};

SecondSectionDetails.defaultProps = {};

export default SecondSectionDetails;
